import React from 'react'
import Create from './create'

const getIDFromPathname = pathname =>
  pathname
    .split('/')
    .pop()

function CheckoutOfferItem(props) {
  const id = getIDFromPathname(props.location.pathname)
  return <Create {...props} id={id} />
}

export default CheckoutOfferItem;